import React, { useState, useEffect } from 'react'
import classes from '../../components/utils/form/SecondaryGrid.module.css';
import moment from 'moment';
// assets
import reload from '../../assets/icons/actualizar.svg'
// components
import Button from "@material-ui/core/Button";
import Buttons from '../../components/utils/buttonsContainer/Buttons';
import HeaderButton from '../../components/utils/button/HeaderButton';
import ServerPaginationGrid from '../../components/utils/grid/PaginatedGrid';
// const
import { movimientos as titles } from '../../consts/titulos-de-tablas';
// services
import { MWMovimientoGet } from '../../services/hooli-services';
// recoil 
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { userCredentials, rowSelected, formIsOpen } from '../../recoilState/GlobalState';

export default function ModalMovimientos({ props }) {
    const [movimientos, setMovimientos] = useState([]);
    const [cargando, setCargando] = useState(true);
    const { row } = useRecoilValue(rowSelected);
    const credentials = useRecoilValue(userCredentials);
    const setFormOpen = useSetRecoilState(formIsOpen);

    const { moneda } = props;

    async function retrieveMovimientos(page, pageLen) {
        const payload = {
            ...credentials,
            "merchantId": credentials.merchantId,
            "monedaCod": moneda,
            "fecha": row.id,
            "page": page + 1,
            "pageLen": pageLen
        };
        setCargando(true);
        const data = await MWMovimientoGet(payload);
        console.log(data)
        setCargando(false);
        return {
            rows: data.movimientos.reduce((acc, item, index) => {
                return [...acc, {
                    id: index,
                    [titles[0]]: item.movimientoFecha.substring(11),
                    [titles[1]]: item.tipoMovimientoNombre,
                    [titles[2]]: item.movimientoDebito,
                    [titles[3]]: item.movimientoCredito,
                    [titles[4]]: item.saldoParcial,
                    [titles[5]]: item.movimientoOrigenDestino,
                    [titles[6]]: item.movimientoReferencia,
                    [titles[7]]: item.movimientoConcepto,
                    [titles[8]]: item.movimientoTransaccionUUID,
                }]
            }, []),
            rowCount: data.totalDeMovimientos
        };
    };

    return (
        <div className={classes.container}>
            <h2 className={classes.title}>Movimientos - {moment(row.id).format('DD/MM/YYYY')}</h2>
            <Buttons>
                <HeaderButton
                    text={"Actualizar pagina"}
                    icon={reload}
                    updateList={retrieveMovimientos}
                />
            </Buttons>
            <div className={classes.gridContainer} >
                <ServerPaginationGrid
                    titles={titles}
                    route='/saldos'
                    updateList={retrieveMovimientos}
                    isLoading={cargando}
                />
            </div>
            <div className={classes.closeButtonContainer}>
                <Button className={classes.button} onClick={() => setFormOpen(false)}>Cerrar</Button>
            </div>
        </div>
    )
}