import React, { useState } from 'react';
import classes from './Header.module.scss';
import { Link } from 'react-router-dom';
// Components
import MenuIcon from "@material-ui/icons/Menu";
import { Drawer, IconButton, List, ListItem, ListItemIcon } from '@material-ui/core';
// Const
import { Routes } from '../../../consts/Routes-App';
import { fallbackURL } from '../../../consts/FallbackURL';
// Recoil
import { routeInfo, userCredentials, userInfo } from '../../../recoilState/GlobalState';
import { useRecoilValue } from 'recoil';
// Assets
import LogoHooli from '../../../assets/icons/logo-hooli-header.svg';
import LogoutIcon from "../../../assets/icons/icon-logout.svg";
import IconDashboard from "../../../assets/icons/icon-dashboard.svg";
// Types
import { UserCredentialsType } from '../../../types/types';

export default function Header() {
    const { wordKey } = useRecoilValue(routeInfo);
    const credentials = useRecoilValue(userCredentials) as UserCredentialsType;
    const info = useRecoilValue(userInfo);

    const [openDrawer, setOpenDrawer] = useState(false);

    const handleDrawerOpen = () => setOpenDrawer(true);
    const handleDrawerClose = () => setOpenDrawer(false);

    const logout = () => {
        localStorage.clear();
        window.location.assign(fallbackURL);
    };

    return (
        <>
            <header className={classes.header}>
                <IconButton
                    edge="start"
                    aria-label="menu"
                    color="inherit"
                    onClick={handleDrawerOpen}
                >
                    <MenuIcon className={classes.menuIcon} />
                </IconButton>
                <div>
                    <img src="" alt="" />
                    <p>{credentials.merchantRZ} {'>'} {wordKey}</p>
                </div>
                <img src={LogoHooli} alt="Hooli" />
            </header>
            <Drawer
                className={classes.drawer}
                variant="persistent"
                anchor="left"
                open={openDrawer}
            >
                <div className={classes.userDescription}>
                    <img className={classes.avatar} alt="avatar" src={info.imagePathPERFIL} />
                    <div className={classes.usernameContainer}>
                        <p>{info.usuarioApellidos} {info.usuarioNombres.split(' ')[0]}</p>
                        <div className={classes.logout} onClick={() => logout()}>
                            <img src={LogoutIcon} alt="logout" />
                            <p>Cerrar sesión</p>
                        </div>
                    </div>
                </div>
                <div className={classes.listContainer}>
                    <List component="div" disablePadding>
                        {credentials.rolCod === ('MD' || 'MA') &&
                            <Link to={Routes.dashboard.route} className={classes.link}>
                                <ListItem button className={classes.itemList} onClick={() => setOpenDrawer(false)}>
                                    <ListItemIcon>
                                        <img className={classes.iconsDrawer} src={IconDashboard} alt="Tables" />
                                    </ListItemIcon>
                                    <span className={classes.textColor}>Volver al dashboard</span>
                                </ListItem>
                            </Link>
                        }
                        <Link to={Routes.pagos.cobros.route} className={classes.link}>
                            <ListItem button className={classes.itemList} onClick={() => setOpenDrawer(false)}>
                                <ListItemIcon>
                                    <img className={classes.iconsDrawer} src={IconDashboard} alt="Tables" />
                                </ListItemIcon>
                                <span className={classes.textColor}>Ver cobros</span>
                            </ListItem>
                        </Link>
                        <Link to={`${Routes.pagos.paso1.route}?tipo=L`} className={classes.link}>
                            <ListItem button className={classes.itemList} onClick={() => setOpenDrawer(false)}>
                                <ListItemIcon>
                                    <img className={classes.iconsDrawer} src={IconDashboard} alt="Tables" />
                                </ListItemIcon>
                                <span className={classes.textColor}>Link de pago</span>
                            </ListItem>
                        </Link>
                        <Link to={`${Routes.pagos.paso1.route}?tipo=S`} className={classes.link}>
                            <ListItem button className={classes.itemList} onClick={() => setOpenDrawer(false)}>
                                <ListItemIcon>
                                    <img className={classes.iconsDrawer} src={IconDashboard} alt="Tables" />
                                </ListItemIcon>
                                <span className={classes.textColor}>Solicitud de pago</span>
                            </ListItem>
                        </Link>
                    </List>
                </div>
                <div className={classes.drawerFooter}>
                    ® Hooli | Todos los derechos reservados
                </div>
            </Drawer>
            {openDrawer ? <div className={classes.drawerHandler} onClick={() => setOpenDrawer(false)} ></div> : null}
        </>
    )
};