import axiosPost from '../axiosPost';
import { MerchantUsuarioType, UserCredentialsType } from '../../../types/types';
import { AxiosPostBody } from '../../../types/AxiosPost';

export const MerchantUsuarioGet = async (arg: UserCredentialsType) => {
    const body: AxiosPostBody = {
        "service": "MerchantUsuarioGet",
        "params": {}
    }

    try {
        const result = await axiosPost<MerchantUsuarioType>('Y', body, arg);
        return result.result
        
    } catch (err) {
        console.log(err)
    }
}