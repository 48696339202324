import React, { useState } from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Drawer from "@material-ui/core/Drawer";
// assets
import LogoutIcon from "../../assets/icons/icon-logout.svg";
// components
import ListDrawer from "../list/ListDrawer";
// styles
import { HeaderStyles } from "./Header.styles";
// recoil
import { useRecoilValue } from 'recoil';
import { userInfo, routeInfo, userCredentials } from '../../recoilState/GlobalState';
import { logout } from "../../services/Local-services";

export default function Header() {
    const [openDrawer, setOpenDrawer] = useState(false);
    
    const credentials = useRecoilValue(userCredentials);
    const info = useRecoilValue(userInfo);
    const route = useRecoilValue(routeInfo);

    const handleDrawerOpen = () => setOpenDrawer(true);
    const handleDrawerClose = () => setOpenDrawer(false);
    
    const classes = HeaderStyles();

    return (
        <>
            <AppBar position="relative" className={classes.root}>
                <Toolbar variant="dense" className={classes.toolbar}>
                    <IconButton
                        edge="start"
                        aria-label="menu"
                        color="inherit"
                        onClick={handleDrawerOpen}
                    >
                        <MenuIcon className={classes.menuIcon} />
                    </IconButton>
                    <Typography variant="h4" color="inherit" className={classes.wordKey}>
                        {route.wordKey + " - " + credentials?.merchantRZ}
                    </Typography>
                </Toolbar>
            </AppBar>
            <Drawer
                className={classes.drawer}
                variant="persistent"
                anchor="left"
                open={openDrawer}
            >
                <div className={classes.userDescription}>
                    <img className={classes.avatar} alt="avatar" src={info.imagePathPERFIL} />
                    <div className={classes.usernameContainer}>
                        <p>{info.usuarioApellidos} {info.usuarioNombres.split(' ')[0]}</p>
                        <div className={classes.logout} onClick={() => logout()}>
                            <img src={LogoutIcon} alt="logout" />
                            <p>Cerrar sesión</p>
                        </div>
                    </div>
                </div>
                <div className={classes.listContainer}>
                    <ListDrawer setOpen={setOpenDrawer} />
                </div>
                <div className={classes.drawerFooter}>
                    ® Hooli | Todos los derechos reservados
                </div>
            </Drawer>
            {openDrawer ? <div className={classes.drawerHandler} onClick={() => setOpenDrawer(false)} ></div> : null}
        </>
    );
};