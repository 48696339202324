import React from 'react'
import { Divider } from '@material-ui/core';
import { CommonFormStyles } from './CommonForm.styles'

export default function CommonForm(props) {
    const { onSubmit, title, procede, children, divider = true } = props;
    const classes = CommonFormStyles();

    return (
        <form className={classes.root} onSubmit={onSubmit}>
            <h2 className={classes.title} style={{ color: procede === 'cancelar' ? '#f00' : '' }}>{title}</h2>
            {divider && <Divider className={classes.divider} />}
            <div className={classes.inputs}>
                {children}
            </div>
        </form>
    )
};