import { makeStyles } from "@material-ui/core";

export const ModalsStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "&:focus": {
            outline: "none",
        },
        paddingTop: theme.spacing(5),
        width: '100%',
        height: "100vh",
        marginBottom: "5%",
        [theme.breakpoints.up("xl")]: {
            paddingTop: theme.spacing(2),
            marginBottom: "1%",
        },
    },
    paper: {
        display: "flex",
        alignItems: "center",
        position: "relative",
        justifyContent: "flex-start",
        flexDirection: "column",
        outline: "none",
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[10],
        borderRadius: '1rem',
        height: "auto",
        padding: "1rem",
    },
}));