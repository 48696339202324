import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Collapse, List, ListItem, ListItemIcon } from '@material-ui/core';
// assets
import IconDashboard from "../../assets/icons/icon-dashboard.svg";
import IconTables from "../../assets/icons/icon-tables.svg";
import ArrowDown from "../../assets/icons/arrow-down.svg";
// styles
import { ListDrawerStyles } from './ListDrawer.styles';
// const
import { Routes } from "../../consts/Routes-App";
// Recoil
import { useRecoilState, useSetRecoilState } from "recoil";
import { routeInfo, userCredentials } from "../../recoilState/GlobalState";

export default function ListDrawer({ setOpen }) {
    const classes = ListDrawerStyles();
    const setRoute = useSetRecoilState(routeInfo);
    const [credentials, setCredentials] = useRecoilState(userCredentials);
    const [merchantsOpen, setMerchantsOpen] = useState(false);
    const [cobrosOpen, setCobrosOpen] = useState(false);


    const handleClick = (route) => {
        setRoute(route);
        setOpen(false);
    };

    const handleMerchantChange = (merchant) => {
        localStorage.setItem('merchantId', merchant.merchantId);
        localStorage.setItem('merchantRZ', merchant.merchantRZ);
        localStorage.setItem('merchantCUIT', merchant.merchantCUIT);
        localStorage.setItem('rolCod', merchant.rolCod);
        setCredentials({ ...credentials, ...merchant });
        window.location.assign('/dashboard')
    };

    const merchants = JSON.parse(localStorage.getItem('merchants'));

    return (
        <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            className={classes.root}
        >
            {Object.values(Routes).map((route, index) => index > 0 && index < Object.values(Routes).length - 1 && (
                <Link to={route.route} className={classes.link} key={route.route}>
                    <ListItem button className={classes.itemList} onClick={() => handleClick(route)}>
                        <ListItemIcon>
                            <img className={classes.iconsDrawer} src={route.route === '/' ? IconDashboard : IconTables} alt="Tables" />
                        </ListItemIcon>
                        <span className={classes.textColor}>{route.wordKey}</span>
                    </ListItem>
                </Link>
            ))}
            <ListItem button className={classes.itemList} onClick={() => setCobrosOpen(!cobrosOpen)}>
                <ListItemIcon>
                    <img className={classes.iconsDrawer} src={IconTables} alt="Tables" />
                </ListItemIcon>
                <span className={classes.textColor}>Cobros</span>
                <img src={ArrowDown} alt="arrow-down" />
            </ListItem>
            <Collapse in={cobrosOpen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <Link to={Routes.pagos.cobros.route} className={classes.link}>
                        <ListItem button className={classes.itemList} onClick={() => setOpen(false)}>
                            <ListItemIcon>
                                <img className={classes.iconsDrawer} src={IconTables} alt="Tables" />
                            </ListItemIcon>
                            <span className={classes.textColor}>Ver cobros</span>
                        </ListItem>
                    </Link>
                    <Link to={`${Routes.pagos.paso1.route}?tipo=L`} className={classes.link}>
                        <ListItem button className={classes.itemList} onClick={() => setOpen(false)}>
                            <ListItemIcon>
                                <img className={classes.iconsDrawer} src={IconTables} alt="Tables" />
                            </ListItemIcon>
                            <span className={classes.textColor}>Link de pago</span>
                        </ListItem>
                    </Link>
                    <Link to={`${Routes.pagos.paso1.route}?tipo=S`} className={classes.link}>
                        <ListItem button className={classes.itemList} onClick={() => setOpen(false)}>
                            <ListItemIcon>
                                <img className={classes.iconsDrawer} src={IconTables} alt="Tables" />
                            </ListItemIcon>
                            <span className={classes.textColor}>Solicitud de pago</span>
                        </ListItem>
                    </Link>
                </List>
            </Collapse>
            {/* Merchants */}
            {merchants?.filter(m => m.merchantId !== parseInt(credentials.merchantId)).length > 0 &&
                <>
                    <ListItem button className={classes.itemList} onClick={() => setMerchantsOpen(!merchantsOpen)}>
                        <ListItemIcon>
                            <img className={classes.iconsDrawer} src={IconTables} alt="Tables" />
                        </ListItemIcon>
                        <span className={classes.textColor}>Cambiar de comercio</span>
                        <img src={ArrowDown} alt="arrow-down" />
                    </ListItem>
                    <Collapse in={merchantsOpen} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            {merchants.filter(m => m.merchantId !== parseInt(credentials.merchantId))
                                .map(m => (<ListItem
                                    key={m.merchantId}
                                    button
                                    className={classes.nested}
                                    onClick={() => handleMerchantChange(m)}>
                                    <span className={classes.textColor}>{m.merchantRZ}</span>
                                </ListItem>
                                ))}
                        </List>
                    </Collapse>
                </>
            }
        </List>
    );
}
