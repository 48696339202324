import axios from 'axios';
import { fallbackURL } from '../../consts/FallbackURL';
import { serviceURL } from '../../consts/ServiceURL';
import { AxiosPostBody, AxiosResponse } from '../../types/AxiosPost';
import { UserCredentialsType } from '../../types/types';


type HeadersType = {
    'Content-Type': 'application/json',
    'x-hooli-token'?: string
    'x-session-id'?: string
}

export default async function axiosPost<T>(loginReq: 'Y' | 'N', body: AxiosPostBody, arg: UserCredentialsType): Promise<AxiosResponse<T>> {
    const headers: HeadersType = {
        'Content-Type': 'application/json'
    }

    if (loginReq === 'Y') {
        headers['x-hooli-token'] = arg.hooliToken
        headers['x-session-id'] = arg.sessionId
    }

    try {
        const result: AxiosResponse<T> = await (await axios.post(serviceURL, body, { headers })).data;
        if (!result || result === null || result === undefined) {
            return {
                status: {
                    code: 0,
                    errcode: 99999,
                    errmsg: 'Error inesperado en request',
                    action: 'U',
                    id: null
                },
                result: []
            }
        }
        if (result.status.errmsg === "Su sesión ha expirado por falta de actividad") {
            localStorage.clear();
            window.location.assign(fallbackURL);
        };
        return result
    } catch (err: any) {
        return {
            status: {
                code: 0,
                errcode: err.response ? err.response.status : 0,
                errmsg: err.message,
                action: 'U',
                id: null
            },
            result: []
        }
    }
};