export const Routes = {
    login: {
        route: "/"
    },
    dashboard: {
        route: "/dashboard",
        wordKey: "Dashboard",
    },
    saldos: {
        route: "/saldos",
        wordKey: "Saldos",
    },
    ventas: {
        route: '/ventas',
        wordKey: "Ventas",
    },
    aCobrar: {
        route: '/aCobrar',
        wordKey: "A Cobrar"
    },
    resumen: {
        route: '/resumen',
        wordKey: "Resumen"
    },
    pagos: {
        route: '/cobros',
        wordKey: "Cobros",
        paso1: {
            route: "/cobros/paso1",
            wordKey: "Cobros > Datos > Paso 1"
        },
        paso2: {
            route: "/cobros/paso2",
            wordKey: "Cobros > Datos > Paso 2"
        },
        cobros: {
            route: '/cobros/cobros',
            wordKey: "Cobros > Ver cobros"
        }
    },
};