import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
// Components
import { PagosHeader } from './components/pagos/index'
import { Header, SnackBar, ProtectedRoute } from './components/index'
// pages
import { Login, Dashboard, Saldos, Ventas, ACobrar, Movimientos, PagosPaso1, PagosHome, PagosPaso2, PagosCobros } from './pages';
// consts
import { Routes } from "./consts/Routes-App";
//Credentials services
import { usuarioMinimosGet, checkAuth, getUserCredentials } from './services/hooli-services';
import { } from "./services/hooli-services/checkAuth";
// recoil
import { useRecoilState, useSetRecoilState } from 'recoil';
import { userInfo, userCredentials, routeInfo } from './recoilState/GlobalState';
import 'dotenv/config'

export default function App() {
    const setInfo = useSetRecoilState(userInfo);
    const [credentials, setCredentials] = useRecoilState(userCredentials);
    const [route, setRoute] = useRecoilState(routeInfo);

    useEffect(() => {
        if (window.location.pathname !== Routes.login.route) {
            (async () => {
                // const data = await getUserCredentials();
                // setCredentials({ ...data, merchantId: 132, merchantRZ: 'Moov', merchantNombre: 'Moov', rolCod: 'MD' });
            })();
            setCredentials(checkAuth());
        }
        if (Routes[window.location.pathname.split('/')[1]]) setRoute(Routes[window.location.pathname.split('/')[1]])
    }, []);


    useEffect(() => {
        async function retrieveUsuarioMinimo() {
            const data = await usuarioMinimosGet(credentials)
            if (data) setInfo(data)
        };
        if (Object.keys(credentials).length > 0) retrieveUsuarioMinimo()
    }, [credentials]);

    useEffect(() => {
        if (Object.keys(credentials).length === 0) return;
        // Visita una ruta que no es login ni cobros y no es admin
        const noAutorizado = route.route !== Routes.login.route && credentials.rolCod !== 'MD' && credentials.rolCod !== 'MA' && !route.route.includes('/cobros');
        if (noAutorizado) return window.location.assign('/cobros');
    }, [route, credentials])

    return (
        <Router>
            {route.route.includes('/cobros') ? <PagosHeader /> : route.route !== '/' && <Header />}
            <SnackBar />
            <Switch>
                {/* Login */}
                <Route path={Routes.login.route} exact render={() => (<Login />)} />
                {/* Dashboard */}
                <Route path={Routes.dashboard.route} exact render={() => (
                    <ProtectedRoute>
                        <Dashboard />
                    </ProtectedRoute>
                )} />
                {/* Tablas */}
                <Route path={Routes.saldos.route} exact render={() => (
                    <ProtectedRoute>
                        <Saldos />
                    </ProtectedRoute>
                )} />
                <Route path={Routes.ventas.route} exact render={() => (
                    <ProtectedRoute>
                        <Ventas />
                    </ProtectedRoute>
                )} />
                <Route path={Routes.aCobrar.route} exact render={() => (
                    <ProtectedRoute>
                        <ACobrar />
                    </ProtectedRoute>
                )} />
                <Route path={Routes.resumen.route} exact render={() => (
                    <ProtectedRoute>
                        <Movimientos />
                    </ProtectedRoute>
                )} />
                {/* Pagos */}
                <Route path={Routes.pagos.route} exact render={() => (<PagosHome />)} />
                <Route path={Routes.pagos.paso1.route} exact render={() => (<PagosPaso1 />)} />
                <Route path={Routes.pagos.paso2.route} exact render={() => (<PagosPaso2 />)} />
                <Route path={Routes.pagos.cobros.route} exact render={() => (<PagosCobros />)} />
            </Switch>
        </Router>
    );
};