import React from 'react';
import { FiltrosStyles } from '../Filtros.styles';
// assets
import reload from "../../assets/icons/actualizar.svg";
// Form
import { useFormik } from 'formik';
import * as yup from 'yup';
// Components
import HeaderButton from "../../components/utils/button/HeaderButton";
import Buttons from '../../components/utils/buttonsContainer/Buttons';
import SubmitButton from "../../components/utils/button/SubmitButton";
import { FormikDateInput } from '../../components/utils/inputs/FormikInputs';

const validationSchema = yup.object({
    desde: yup.date().max(yup.ref('hasta'), 'No puede ser superior a la segunda fecha'),
    hasta: yup.date().max(new Date().toISOString().substring(0, 10), 'Fecha maxima hoy')
});

export default function Filtros(props) {
    const { filtros, setFiltros, cargando, updateList } = props;

    const formik = useFormik({
        initialValues: filtros,
        validationSchema,
        onSubmit: (values) => setFiltros(values)
    });

    const classes = FiltrosStyles();
    const data = { desde: {label: "Desde"}, hasta: { label: 'Hasta' } };

    return (
        <form className={classes.root} onSubmit={formik.handleSubmit}>
            <div className={classes.filtros}>
                <FormikDateInput
                    data={data}
                    name="desde"
                    formik={formik}
                    disabled={cargando}
                    className={classes.input}
                />
                <FormikDateInput
                    data={data}
                    name="hasta"
                    formik={formik}
                    disabled={cargando}
                    className={classes.input}
                />
                <div className={classes.marginNormal}>
                    <SubmitButton texto="Filtrar" />
                </div>
            </div>
            <Buttons>
                <HeaderButton
                    text={"Actualizar pagina"}
                    icon={reload}
                    updateList={updateList}
                />
            </Buttons>
        </form>
    )
};