import { fallbackURL } from "../../consts/FallbackURL";

export const checkAuth = () => {
    if (localStorage.getItem('hooliToken') && localStorage.getItem('sessionId')) {
        return {
            hooliToken: localStorage.getItem('hooliToken'),
            sessionId: localStorage.getItem('sessionId'),
            merchantId: localStorage.getItem('merchantId'),
            merchantRZ: localStorage.getItem('merchantRZ'),
            merchantCUIT: localStorage.getItem('merchantCUIT'),
            rolCod: localStorage.getItem('rolCod')
        }
    };

    return window.location.assign(fallbackURL);
};