import { AxiosPostBody } from '../../../types/AxiosPost';
import { UserCredentialsType } from '../../../types/types';
import axiosPost from '../axiosPost';

type Args = UserCredentialsType & {
    merchantId: number
};

export const MWDashboardSaldosGet = async (arg: Args) => {
    const { merchantId } = arg

    const body: AxiosPostBody = {
        "service": "MWDashboardSaldosGet",
        "params": {
            "merchantId": merchantId,
        }
    }

    try {
        const result = await axiosPost('Y', body, arg);
        

        return result.result[0]
    } catch (err) {
        console.log(err)
    }
}