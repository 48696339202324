import React, { useEffect, useState } from "react";
// styles
import classes from "./dashboard.module.scss";
// assets
import Ventas from '../../assets/icons/ventas.svg';
import PorCobrar from '../../assets/icons/por-cobrar.svg';
// components
import Card from "../../components/utils/cards/Card";
import Saldos from "../../components/utils/cards/Saldos";
import Solicitudes from "../../components/utils/cards/Solicitudes";
// Services
import {
    MWDashboardVentasGet, MWDashboardPorCobrarGet, MWDashboardSaldosGet, MWDashboardPendientesGet, MWUltimaAcreditacionGet
} from "../../services/hooli-services";
// Recoil
import { useRecoilValue, useSetRecoilState } from "recoil";
import { routeInfo, userCredentials } from '../../recoilState/GlobalState'
import { Routes } from "../../consts/Routes-App";

export default function Dashboard() {
    const [ventasHoy, setVentasHoy] = useState({ H: { monto: 0, operaciones: 0 }, C: { monto: 0, operaciones: 0 }, D: { monto: 0, operaciones: 0 }, B: { monto: 0, operaciones: 0 }, T: { monto: 0, operaciones: 0 } });
    const [ventasSemana, setVentasSemana] = useState({});
    const [cobrarHoy, setCobrarHoy] = useState({ H: { monto: 0, operaciones: 0 }, C: { monto: 0, operaciones: 0 }, D: { monto: 0, operaciones: 0 }, B: { monto: 0, operaciones: 0 }, T: { monto: 0, operaciones: 0 } });
    const [cobrarFuturo, setCobrarFuturo] = useState({});
    const [totales, setTotales] = useState({});
    const [pendientes, setPendientes] = useState({ monto: 0, operaciones: 0 });
    const [ultimoMovimiento, setUltimoMovimiento] = useState({});
    const [cargando, setCargando] = useState(true);

    const credentials = useRecoilValue(userCredentials);
    const setRoute = useSetRecoilState(routeInfo);

    useEffect(() => {
        if (Object.keys(credentials).length > 0) {
            async function getVentas() {
                const data = await MWDashboardVentasGet(credentials);
                setVentasHoy(data
                    .filter(item => item.posTransaccionFecha === new Date().toISOString().substring(0, 10))
                    .reduce((acc, item) => ({
                        ...acc,
                        [item.posTransaccionMetodoPago]: {
                            monto: item.posTransaccionMontoTotal,
                            operaciones: item.posTransaccionOperaciones
                        }
                    }), { H: { monto: 0, operaciones: 0 }, C: { monto: 0, operaciones: 0 }, D: { monto: 0, operaciones: 0 }, B: { monto: 0, operaciones: 0 }, T: { monto: 0, operaciones: 0 } }));
                setVentasSemana(data
                    .reduce((acc, item) => ({
                        ...acc,
                        [item.posTransaccionFecha]: {
                            ...acc[item.posTransaccionFecha],
                            [item.posTransaccionMetodoPago]: {
                                monto: item.posTransaccionMontoTotal,
                                operaciones: item.posTransaccionOperaciones
                            }
                        }
                    }), {}));
            };
            async function getCobrar() {
                const data = await MWDashboardPorCobrarGet(credentials);

                setCobrarHoy(data
                    .reduce((acc, item) => ({
                        ...acc,
                        [item.posTransaccionMetodoPago]: {
                            monto: item.posTransaccionMontoNetoTotal + acc[item.posTransaccionMetodoPago].monto,
                            operaciones: item.posTransaccionOperaciones + acc[item.posTransaccionMetodoPago].operaciones
                        }
                    }), { H: { monto: 0, operaciones: 0 }, C: { monto: 0, operaciones: 0 }, D: { monto: 0, operaciones: 0 }, B: { monto: 0, operaciones: 0 }, T: { monto: 0, operaciones: 0 } }));
                setCobrarFuturo(data
                    .reduce((acc, item) => ({
                        ...acc,
                        [item.posTransaccionFecha]: {
                            ...acc[item.posTransaccionFecha],
                            [item.posTransaccionMetodoPago]: {
                                monto: item.posTransaccionMontoNetoTotal,
                                operaciones: item.posTransaccionOperaciones
                            }
                        }
                    }), {}));
            };
            async function getTotales() {
                const data = await MWDashboardSaldosGet(credentials);
                setTotales(data);
            };
            async function getPendientes() {
                const data = await MWDashboardPendientesGet(credentials);
                setPendientes(data.reduce((acc, item) => {
                    return {
                        monto: acc.monto + item.posTransaccionMontoTotal,
                        operaciones: acc.operaciones + item.posTransaccionOperaciones,
                    }
                }, { monto: 0, operaciones: 0 }));
            };
            async function getUltimoMovimiento() {
                const data = await MWUltimaAcreditacionGet(credentials);
                setUltimoMovimiento(data)
            }
            async function runFunctions() {
                setCargando(true);
                await Promise.all([getVentas(), getCobrar(), getTotales(), getPendientes(), getUltimoMovimiento()])
                setCargando(false);
            };
            runFunctions();
        }
    }, [credentials]);
  
    useEffect(() => {
        setRoute(Routes[window.location.pathname.split('/')[1]])
    }, []);

    return (
        <main className={classes.main}>
            <div className={classes.container}>
                <div className={classes.cards}>
                    <Card
                        dataHoy={ventasHoy}
                        dataSerie={ventasSemana}
                        totales={getTotales(ventasHoy)}
                        title="Ventas del día"
                        link="/ventas"
                        image={Ventas}
                        cargando={cargando}
                    />
                    <Card
                        dataHoy={cobrarHoy}
                        dataSerie={cobrarFuturo}
                        totales={getTotales(cobrarHoy)}
                        title="Por cobrar"
                        link="/aCobrar"
                        image={PorCobrar}
                        cargando={cargando}
                        ultimoMovimiento={ultimoMovimiento}
                    />
                    <Saldos totales={totales} cargando={cargando} />
                    <Solicitudes pendientes={pendientes} cargando={cargando} />
                </div>
            </div>
        </main>
    );
};

const getTotales = (data) => {
    return Object.values(data).reduce((acc, key) => ({
        monto: acc.monto + key.monto,
        operaciones: acc.operaciones + key.operaciones
    }), { monto: 0, operaciones: 0 })
}
